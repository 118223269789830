<template>
  <div
    class="au-markdown"
    :class="{ 'has-error': required && !modelValue }"
  >
    <div class="tabs-header">
      <div class="navigation-tabs">
        <AuButton
          border-radius="8"
          center
          height="34px"
          :type="activeTab == 'edit' ? 'primary' : 'secondary'"
          @click="activeTab = 'edit'"
        >
          Edit
        </AuButton>
        <AuButton
          border-radius="8"
          center
          height="34px"
          :type="activeTab == 'view' ? 'primary' : 'secondary'"
          @click="activeTab = 'view'"
        >
          View
        </AuButton>
        <AuButton
          border-radius="8"
          center
          height="34px"
          :type="activeTab == 'html' ? 'primary' : 'secondary'"
          @click="activeTab = 'html'"
        >
          HTML
        </AuButton>
      </div>
      <div class="formatting-tools">
        <AuButton
          border-radius="8"
          center
          width="34px"
          height="34px"
          @click="formatText('header')"
        >
          H
        </AuButton>

        <AuButton
          border-radius="8"
          center
          width="34px"
          height="34px"
          @click="formatText('bold')"
        >
          B
        </AuButton>

        <AuButton
          border-radius="8"
          center
          width="34px"
          height="34px"
          @click="formatText('italic')"
        >
          <i>I</i>
        </AuButton>

        <AuButton
          border-radius="8"
          center
          width="34px"
          height="34px"
          icon="wys-list"
          @click="formatText('list')"
        />

        <AuButton
          border-radius="8"
          center
          width="34px"
          height="34px"
          icon="wys-marks"
          @click="formatText('quote')"
        />

        <AuButton
          border-radius="8"
          center
          width="34px"
          height="34px"
          icon="wys-clear"
          @click="formatText('clear')"
        />
      </div>
    </div>

    <div
      v-show="activeTab === 'edit'"
      class="edit-area"
    >
      <textarea
        ref="textarea"
        :value="modelValue"
        :placeholder="placeholder"
        @input="handleInput"
      />
    </div>

    <div
      v-show="activeTab === 'view' && showView"
      class="view-area"
    >
      <pre>{{ modelValue }}</pre>
    </div>

    <div
      v-show="activeTab === 'html' && showHTML"
      class="view-area"
    >
      <AuMarkdownView :content="modelValue" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuMarkdown",
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Enter your markdown here..."
    },
    required: {
      type: Boolean,
      default: false
    },
    showView: {
      type: Boolean,
      default: true
    },
    showHTML: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeTab: "edit"
    };
  },
  methods: {
    handleInput(e) {
      console.log("Current text:", e.target.value);
      this.updateModelValue(e);
    },
    updateModelValue(e) {
      this.$emit("update:modelValue", e.target.value);
    },
    formatText(type) {
      const textarea = this.$refs.textarea;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const selectedText = this.modelValue.substring(start, end);
      let before = this.modelValue.substring(0, start);
      let after = this.modelValue.substring(end);

      let newText = selectedText;

      switch (type) {
        case "bold":
          newText = `**${selectedText}**`;
          break;
        case "header":
          newText = `### ${selectedText}`;
          break;
        case "italic":
          newText = `_${selectedText}_`;
          break;
        case "list":
          newText = `- ${selectedText}`;
          break;
        case "quote":
          newText = `> ${selectedText}`;
          break;
        case "clear":
          newText = selectedText
            .replace(/\*\*/g, "")
            .replace(/_/g, "")
            .replace(/^#+\s*/g, "")
            .replace(/^-\s*/g, "")
            .replace(/^>\s*/g, "");
          break;
      }

      const updatedValue = before + newText + after;
      console.log("Formatted text:", updatedValue);
      this.$emit("update:modelValue", updatedValue);

      this.$nextTick(() => {
        textarea.focus();
        textarea.selectionStart = start;
        textarea.selectionEnd = start + newText.length;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.au-markdown {
  border: 1px solid var(--border-color);
  border-radius: 14px;
  padding: 10px;
  padding-bottom: 8px;
  overflow: hidden;
  font-family: "Suisse Intl", sans-serif;

  &.has-error {
    border-color: #ff000069;
  }

  .tabs-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 10px;

    @media (min-width: 700px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .navigation-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    @media (min-width: 700px) {
      margin-right: 8px;
    }
  }

  .formatting-tools {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: flex-start;

    @media (min-width: 700px) {
      justify-content: flex-end;
    }
  }

  .edit-area, .view-area {
    min-height: 100px;
  }
  .view-area {
    margin-bottom: 6px;
  }

  textarea {
    width: 100%;
    min-height: 100px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    margin: 0;
    padding: 10px;
    font-family: "Suisse Intl", sans-serif;
    resize: vertical;
    font-size: 13px;
    color: var(--color-50);

    &:focus {
      outline: none;
    }
  }

  .view-area {
    border: 1px solid var(--border-color);
    background-color: var(--main-color);
    border-radius: 8px;
    white-space: pre-wrap;
    font-size: 14px;

    pre {
      margin: 0;
      padding: 10px;
      font-family: "Suisse Intl", sans-serif;
      font-size: 13px;
    }
  }
}
</style>