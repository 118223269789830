<template>
  <div class="au-view">
    <div v-html="compiledMarkdown" />
  </div>
</template>

<script>
export default {
  name: "AuView",
  props: {
    content: {
      type: String,
      default: ""
    }
  },
  computed: {
    compiledMarkdown() {
      if (!this.content) {
        return "";
      }

      // Защита от XSS
      const escapeHtml = (unsafe) => {
        return unsafe
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;");
      };

      let html = escapeHtml(this.content);

      // Обработка строк построчно
      let lines = html.split("\n");
      let result = [];

      lines.forEach(line => {
        // Обработка цитат (>)
        if (line.trim().startsWith("&gt;")) {
          const content = line.replace(/^&gt;\s*/, "");
          result.push(`<div class="au-blockquote">${content}</div>`);
        }
        // Обработка заголовков (###)
        else if (line.includes("### ")) {
          result.push(line.replace(/### (.*)/, "<h3>$1</h3>"));
        }
        // Обработка списков (-)
        else if (line.trim().startsWith("- ")) {
          result.push(line.replace(/^- (.*)/, "<li>$1</li>"));
        }
        // Обычный текст
        else {
          result.push(line);
        }
      });

      // Собираем обратно
      html = result.join("<br>");

      // Обработка форматирования в тексте
      html = html.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
      html = html.replace(/_([^_]+)_/g, "<em>$1</em>");

      // Обработка списков (оборачиваем в ul)
      html = html.replace(/(<li>.*?<\/li>)+/g, matches => `<ul>${matches}</ul>`);

      return html;
    }
  }
};
</script>

<style scoped>
.au-view {
  font-family: "Suisse Intl", sans-serif;
  line-height: 1.1;
  padding: 10px;
}

.au-view :deep(h3) {
  font-size: 22px;
  margin-bottom: 0.8em;
}

.au-view :deep(strong) {
  font-weight: bold;
}

.au-view :deep(em) {
  font-style: italic;
}

.au-view :deep(ul) {
  padding-left: 1.5em;
  margin: 0.5em 0;
}

.au-view :deep(li) {
  list-style-type: disc;
  margin-bottom: 0.3em;
}

.au-view :deep(br) {
  content: "";
  display: block;
  margin: 0.5em 0;
}
</style>

<style>
/* Глобальные стили для цитат иначе не работают */
.au-blockquote {
  border-left: 3px solid var(--color-80);
  padding-left: 1em;
  margin: 1em 0;
  color: var(--color-50);
  font-style: italic;
}
</style>