<template>
  <AuAccordion
    :label="$t('auCapitals.pools.details')"
    class="card accordion"
  >
    <template #title>
      <div class="header">
        <div>
          <span>30 {{ $t("auCapitals.pools.days") }}</span>
          <span>1x {{ $t("auCapitals.pools.multiplier") }}</span>
        </div>

        <div :notBorder="true">
          <span>{{ $t("auCapitals.pools.earned") }}</span>
          <span>0.00 AUT</span>
        </div>

        <div>
          <span>{{ $t("auCapitals.pools.APR") }}</span>
          <span>{{ project.profitability }}%</span>
        </div>

        <div>
          <span>{{ $t("auCapitals.pools.remaning") }}</span>
          <AuDecimalStatic
            :value="remaning"
            :decimal="project.mnemonicDecimals"
            :addition="project.mnemonicEng"
          />
        </div>

        <div>
          <span>{{ $t("auCapitals.pools.lockUpTterm") }}</span>
          <span>30 {{ $t("auCapitals.pools.days") }}</span>
        </div>

        <div>
          <span>{{ $t("auCapitals.pools.withdrawalDelayTime") }}</span>
          <span>None</span>
        </div>
      </div>
    </template>
    <template #content>
      <div class="content">
        <div>
          <ProgressBarWrapper
            :procent="project.progressProcent"
            green
          >
            <template #top-left>
              <span>{{ $t("auCapitals.pools.totalAmount") }}</span>
            </template>

            <template #top-right>
              <AuDecimalStatic
                class="semi-bold"
                :value="project.maxCap"
                :decimal="project.mnemonicDecimals"
                :addition="project.mnemonicEng"
              />
            </template>

            <template #bottom-left>
              <span class="semi-bold">{{ project.progressProcent + "%" }}</span>
            </template>
          </ProgressBarWrapper>
          <AuList :items="items" />
        </div>
        <div>
          <AuButton type="primary">
            {{ $t("auCapitals.pools.invest") }}
          </AuButton>
        </div>
      </div>
    </template>
  </AuAccordion>
</template>

<script>
import BigNumber from "bignumber.js";
import ProgressBarWrapper from "../progressBar/ProgressBarWrapper.vue";

export default {
  components: {
    ProgressBarWrapper
  },

  props: {
    project: {
      type: Object,
      default: null
    }
  },

  computed: {
    items() {
      const titleStyle = { color: "var(--text-color)" };
      const valueStyle = { "font-family": "Suisse Intl Semi Bold" };

      const decimal = this.project.mnemonicDecimals;
      const addition = this.project.mnemonicEng;

      return [
        [
          { title: this.$t("auCapitals.pools.startTime"), value: this.project.dateStart, titleStyle, valueStyle },
          { title: this.$t("auCapitals.pools.endTime"), value: this.project.dateEnd, titleStyle, valueStyle },
          { title: this.$t("auCapitals.pools.stakeAmount"), value: this.project.minCap, titleStyle, valueStyle, decimal, addition },
          { title: this.$t("auCapitals.pools.maximumPerWallet"), value: this.project.quote, titleStyle, valueStyle, decimal, addition }
        ]
      ];
    },

    remaning() {
      return BigNumber(this.project.maxCap).minus(this.project.currentCap).toNumber().toString();
    }
  }
};

</script>

<style lang="scss" scoped>
.accordion {
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;

  width: 100%;

  >div:first-child {
    display: flex;
    flex-direction: column;

    width: 40%;

    span:first-child {
      font-family: Suisse Intl Semi Bold;
      font-size: 24px;
      line-height: 20px;
    }

    span:last-child {
      color: var(--color-50);
      font-size: 12px;
    }
  }

  div[notBorder=true] {
    border-left: none;
  }

  >div:not(:first-child) {
    display: flex;
    flex-direction: column;

    padding-left: 20px;
    padding-right: 20px;
    border-left: 1px dashed var(--color-70);

    height: fit-content;
    width: 20%;

    span:first-child {
      color: var(--color-50);
      font-size: 12px;
    }

    span:last-child {
      font-size: 16px;
    }
  }

  >div:last-child {
    border-right: 1px dashed var(--color-70);
    margin-right: 40px;
  }
}

.content {
  display: flex;
  gap: 20px;

  >div:first-child {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 40%;

    >div {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
}

.semi-bold {
  font-family: Suisse Intl Semi Bold;
}
</style>

<style lang="scss">
.card.accordion {
  width: 100%;

  .au-accordion-header {
    border-bottom-color: var(--color-70);
    border-bottom-style: solid;
    border-top: none;

    padding-top: 0px;
    margin-bottom: -9px;

    >div:first-child {
      width: 100%;
    }

    .chevron__wrap {
      color: #279063;
    }
  }

  .au-accordion-body {
    padding: 22px 0 0px 0;
  }

  .au-accordion-toggler {
    filter: brightness(0) saturate(100%) invert(27%) sepia(56%) saturate(748%) hue-rotate(122deg) brightness(92%) contrast(89%);
  }
}
</style>