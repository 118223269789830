<template>
  <div class="main-panel main-panel__center testpage">
    <AuTabView>
      <AuTabPanel title="Компоненты">
        <div class="block-bc">
          <div class="block-i">
            <BriefcaseHorizontal />
          </div>
          <div class="block-i" />
          <div class="block-i" />
        </div>

        <div class="card p-4 mt-4">
          MarkDown
          <div class="mt-4">
            <AuMarkdown
              v-model="textWs"
              :required="true"
              :show-view="true"
              :placeholder="$t('projects.form.firstStep.projectDescEngPlaceholder')"
              :show-preview="true"
            />
          </div>
        </div>
        <div class="card p-4 mt-4">
          WYSIWYG
          <div class="mt-4">
            <AuWysiwyg
              v-model="textWs"
              :required="true"
              :placeholder="$t('projects.form.firstStep.projectDescEngPlaceholder')"
              :show-preview="true"
            />
          </div>
        </div>

        <div class="card p-4 mt-4">
          <div class="mt-2 mb-2 mt-2">
            default, disable false
          </div>
          <div class="d-flex">
            <AuSwitchButton
              class="dist"
              width="66px"
              border-radius="8"
            >
              {{ $t("All") }}
            </AuSwitchButton>
            <AuSwitchButton
              class="dist"
              width="66px"
              :font-size="14"
            >
              {{ $t("dashboard.rates.buttons.all") }}
            </AuSwitchButton>
            <AuButton
              class="dist"
              center
              @click="getMore"
            >
              {{ $t("Download more") }}
            </AuButton>
          </div>

          <div class="mt-2 mb-2 mt-2">
            primary, disable false
          </div>
          <div class="d-flex">
            <AuSwitchButton
              type="primary"
              class="dist"
              width="66px"
              border-radius="8"
            >
              {{ $t("All") }}
            </AuSwitchButton>
            <AuSwitchButton
              type="primary"
              class="dist"
              @click="getMore"
            >
              {{ $t("dashboard.rates.buttons.all") }}
            </AuSwitchButton>
            <AuButton
              type="primary"
              class="dist"
              center
              @click="getMore"
            >
              {{ $t("Download more") }}
            </AuButton>
          </div>

          <hr>
          <div class="mt-2 mb-2">
            disable true
          </div>
          <div class="d-flex">
            <AuSwitchButton
              disabled
              class="dist"
              width="66px"
              border-radius="8"
            >
              {{ $t("All") }}
            </AuSwitchButton>
            <AuSwitchButton
              disabled="true"
              class="dist"
              width="66px"
              :font-size="14"
            >
              {{ $t("dashboard.rates.buttons.all") }}
            </AuSwitchButton>
            <AuButton
              disabled
              class="dist"
              center
              @click="getMore"
            >
              {{ $t("Download more") }}
            </AuButton>
          </div>

          <ProgressLoader
            v-if="loading"
            mode="indeterminate"
            color="#5AC396"
            size="150px"
          />

          <AuPagination :total-items="totalItems" />
        </div>
      </AuTabPanel>

      <AuTabPanel title="Таблицы">
        <div class="card p-4">
          <span class="mb-4">Таблица</span>
          <AuTable
            :columns="tableColumns"
            :items="tableItems"
          />
        </div>

        <div class="card p-4 mt-4">
          <span class="mb-4">Аккордион</span>
          <AuAccordion
            v-for="(owner, index) in tableItems"
            :key="index"
          >
            <template #title>
              <span>{{ owner.property1 }}</span>
            </template>
            <template #content>
              <div
                v-for="(column, cindex) in tableColumns"
                :key="cindex"
                class="row"
              >
                <div class="d-flex justify-content-between">
                  <span>
                    {{ column.name }}
                  </span>
                  <span>
                    {{ owner[column.property] }}
                  </span>
                </div>
              </div>
            </template>
          </AuAccordion>
        </div>

        <div class="card p-4 mt-4">
          <span class="mb-4">Список</span>
          <AuList :items="listItems" />
        </div>
      </AuTabPanel>

      <AuTabPanel title="Макет">
        <div class="d-flex flex-row gap-3 p-4 mt-4">
          <CapitalCardLoader
            v-for="item in [1, 2, 3, 4]"
            :key="item"
            :is-investment="true"
            height="338px"
          />
        </div>
      </AuTabPanel>

      <AuTabPanel title="Вычисления">
        <div class="card p-4">
          <div class="fs24">
            {{ $t("Smart indent") }}
          </div>

          <AuTabView resizable>
            <AuTabPanel :title="$t('Test')">
              <AuTable
                class="briefcase-table briefcase-table-hiddable"
                :columns="columnsTable"
                :items="itemsTable"
              >
                <template #result="{ value }">
                  <AuDecimalStatic
                    :value="value.value"
                    :decimal="value.decimals"
                    :indent="value.indent"
                    :show-addition="false"
                  />
                </template>
              </AuTable>
            </AuTabPanel>
          </AuTabView>
        </div>
      </AuTabPanel>
    </AuTabView>
  </div>
</template>

<script>
import AuTabPanel from "@/components/extended/tabs/AuTabPanel";
import AuTabView from "@/components/extended/tabs/AuTabView";
import BriefcaseHorizontal from "@/panels/components/BriefcaseHorizontal";
import CapitalCardLoader from "@/components/capital/CapitalCardLoader";
import ProgressLoader from "@/loaders/progress-bar";

export default {
  name: "TestPage",

  components: {
    BriefcaseHorizontal,
    ProgressLoader,
    CapitalCardLoader,
    AuTabPanel,
    AuTabView
  },

  data() {
    return {
      page: 3,
      totalItems: 100,
      textWs: "",
      loading: false
    };
  },

  computed: {
    tableColumns() {
      return [{ name: "Столбец 1", property: "property1" },
        { name: "Столбец 2", property: "property2" },
        { name: "Столбец 3", property: "property3", align: "end" }];
    },

    tableItems() {
      return [
        {
          property1: 11,
          property2: 12,
          property3: 13
        },
        {
          property1: 21,
          property2: 22,
          property3: 23
        }
      ];
    },

    listItems() {
      return [
        [
          { title: "Свойство 1", value: 123 },
          { title: "Свойство 2", value: "123" },
          { title: "Цветное свойство", value: "ВАУ", valueStyle: { "color": "#30BC89" } }
        ],
        [
          { title: "Свойство 3", value: "Yes", checkbox: true },
          { title: "Свойство 4", value: "No", checkbox: false }
        ]
      ];
    },

    typeTitle() {
      return this.$t("transactions.titleType.spending");
    },

    columnsTable() {
      return [
        { name: "data received", align: "start" },
        { name: "decimals", property: "decimals" },
        { name: "indent", property: "indent" },
        { name: "correct result", property: "correct" },
        {
          name: "result",
          align: "center",
          slot: "result",
          property: "result",
          decimal: true
        }
      ];
    },

    itemsTable() {
      return [
        {
          dataReceived: 2200,
          decimals: "6",
          indent: "4",
          correct: "0.0022",
          resultOne: {
            value: "2200",
            decimals: 6,
            indent: 4
          }
        },
        {
          dataReceived: 2200,
          decimals: "6",
          indent: "6",
          correct: "0.0022",
          resultOne: {
            value: "2200",
            decimals: 6,
            indent: 6
          }
        },
        {
          dataReceived: 1000,
          decimals: "2",
          indent: "2",
          correct: "10",
          resultOne: {
            value: "1000",
            decimals: 2,
            indent: 2
          }
        },
        {
          dataReceived: 1001,
          decimals: "2",
          indent: "5",
          correct: "10.01000",
          resultOne: {
            value: "1001",
            decimals: 2,
            indent: 5
          }
        },
        {
          dataReceived: 1010,
          decimals: "2",
          indent: "2",
          correct: "10.10",
          resultOne: {
            value: "1010",
            decimals: 2,
            indent: 2
          }
        },
        {
          dataReceived: 1010,
          decimals: "2",
          indent: "0",
          correct: "10.11",
          resultOne: {
            value: "1010",
            decimals: 2,
            indent: 0
          }
        },
        {
          dataReceived: 1001,
          decimals: "2",
          indent: "0",
          correct: "10.01",
          resultOne: {
            value: "1001",
            decimals: 2,
            indent: 0
          }
        },
        {
          dataReceived: 10 / 3,
          decimals: "2",
          indent: "0",
          correct: "3.33",
          resultOne: {
            value: 10 / 3,
            decimals: 2,
            indent: 0
          }
        },
        {
          dataReceived: "0.12340000123",
          decimals: "18",
          indent: "0",
          correct: "0.12340000123",
          resultOne: {
            value: 0.12340000123,
            decimals: 18,
            indent: 0
          }
        },
        {
          dataReceived: "0.12340000123",
          decimals: "18",
          indent: "2",
          correct: "0.12",
          resultOne: {
            value: 0.12340000123,
            decimals: 18,
            indent: 2
          }
        },
        {
          dataReceived: "0.1234",
          decimals: "18",
          indent: "6",
          correct: "0.123400",
          resultOne: {
            value: 0.1234,
            decimals: 18,
            indent: 6
          }
        },
        {
          dataReceived: "0.1234",
          decimals: "18",
          indent: "0",
          correct: "0.1234",
          resultOne: {
            value: 0.1234,
            decimals: 18,
            indent: 0
          }
        },
        {
          dataReceived: "0.12",
          decimals: "18",
          indent: "0",
          correct: "0.12",
          resultOne: {
            value: 0.12,
            decimals: 18,
            indent: 2
          }
        },
        {
          dataReceived: "20049491780821917808218",
          decimals: "18",
          indent: "2",
          correct: "20 049.49",
          resultOne: {
            value: "20049491780821917808218",
            decimals: 18,
            indent: 2
          }
        },
        {
          dataReceived: "0.10",
          decimals: "18",
          indent: "2",
          correct: "0.10",
          resultOne: {
            value: 0.1,
            decimals: 18,
            indent: 2
          }
        },
        {
          dataReceived: "0",
          decimals: "?",
          indent: "?",
          correct: "0",
          resultOne: {
            value: "0"
            // decimals: 18,
            // indent: 2
          }
        },
        {
          dataReceived: "30000000000000000",
          decimals: 18,
          indent: 2,
          correct: "0.03",
          resultOne: {
            value: "30000000000000000",
            decimals: 18,
            indent: 2
          }
        },

        {
          dataReceived: "1000000.0000",
          decimals: "-",
          indent: "-",
          correct: "1 000 000",
          resultOne: {
            value: 1000000.0
            // decimals: 0
            // indent: 0
          }
        },
        {
          dataReceived: "1000000.1234",
          decimals: "-",
          indent: "4",
          correct: "1 000 000.1234",
          resultOne: {
            value: 1000000.1234,
            // decimals: 0
            indent: 4
          }
        },
        {
          dataReceived: "1000000.120000",
          decimals: "-",
          indent: "-",
          correct: "1 000 000.12",
          resultOne: {
            value: 1000000.12
            // decimals: 0
            // indent: 2
          }
        },
        {
          dataReceived: "1000000.10000",
          decimals: "-",
          indent: "-",
          correct: "1 000 000.10",
          resultOne: {
            value: 1000000.1
            // decimals: 0
            // indent: 2
          }
        },
        {
          dataReceived: "1000000.10000",
          decimals: "-",
          indent: 2,
          correct: "1 000 000.10",
          resultOne: {
            value: "1000000.10000",
            // decimals: 0
            indent: 2
          }
        },
        {
          dataReceived: "123456789",
          decimals: 3,
          indent: "-",
          correct: "123 456 789",
          resultOne: {
            value: "123456789",
            decimals: 3
            // indent: 0
          }
        },
        {
          dataReceived: "123456789",
          decimals: 3,
          indent: 3,
          correct: "123 456.789",
          resultOne: {
            value: "123456789",
            decimals: 3,
            indent: 3
          }
        },
        {
          dataReceived: "12345678902",
          decimals: 2,
          indent: 2,
          correct: "123 456 789.02",
          resultOne: {
            value: "12345678902",
            decimals: 2,
            indent: 2
          }
        },
        {
          dataReceived: "1234567892000",
          decimals: 4,
          indent: 4,
          correct: "123 456 789.2000",
          resultOne: {
            value: "1234567892000",
            decimals: 4,
            indent: 4
          }
        },
        {
          dataReceived: "1234567892001",
          decimals: 4,
          indent: 0,
          correct: "123 456 789.2001",
          resultOne: {
            value: "1234567892001",
            decimals: 4,
            indent: 0
          }
        },
        {
          dataReceived: "1234567892001",
          decimals: 4,
          indent: 2,
          correct: "123456789.20",
          resultOne: {
            value: "1234567892001",
            decimals: 4,
            indent: 2
          }
        }
      ];
    }
  },

  methods: {
    getMore() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }
};
</script>

<style>
.testpage .tabs_info {
  background: none;
  border: 2px var(--card-color) solid;
}
</style>

<style scoped lang="scss">

.main-panel {
  display: flex;

  &__center {
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: auto;
    padding: 20px 25px 15px 25px;
    background-color: var(--main-color);
  }
}

.dist {
  margin-right: 4px;
}

.block-bc {
  display: flex;
  gap: 10px;
}

.block-i {
  flex: 1;
  border-radius: 15px;
  background-color: #d1d1d17c;
  max-width: calc(33.33% - 10px);
  box-sizing: border-box;
}
</style>
