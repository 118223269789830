<template>
  <div class="au-accordion">
    <div
      :is-open="isOpen"
      class="au-accordion-header"
    >
      <div>
        <slot name="title" />
      </div>
      <div
        class="chevron__wrap"
        @click="toggleAccordion()"
      >
        <span v-if="label">{{ label }}</span>

        <AuIcon
          icon="chevron_down"
          class="au-accordion-toggler"
          :aria-expanded="isOpen"
          :aria-controls="`collapse-${uid}`"
          @click="toggleAccordion()"
        />
      </div>
    </div>

    <div
      v-show="isOpen"
      :id="`collapse-${uid}`"
      class="au-accordion-body"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      isOpen: true,
      uid: ""
    };
  },

  mounted() {
    this.uid = this.randomString();
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },

    randomString() {
      let rnd = "";
      while (rnd.length < 20) {
        rnd += Math.random().toString(36).substring(2);
      }
      return rnd.substring(0, 20);
    }
  }
};
</script>

<style lang="scss" scoped>
  .chevron__wrap {
    display: flex;
    align-items: center;
    gap: 5px;

    height: fit-content;

    cursor: pointer;
  }

  .au-accordion {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 0;
      border-top: 1px solid var(--color-70);
      border-bottom: 1px dashed var(--color-70);
      border-left-width: 0px;
      border-right-width: 0px;
      &[is-open="false"] {
        border-bottom-width: 0px;
      }
    }
    &-checkbox {
      margin-left: 10px;
    }
    &-body {
      padding: 22px 0 10px 0;
    }
    &-toggler {
      cursor: pointer;
      &[aria-expanded="true"] {
        transform: rotate(180deg);
      }
    }

    &[last-child="true"] {
      .au-accordion-header {
        border-bottom-width: 1px;
      }
    }
  }
  .col {
  padding: 0;
  font-size: 12px;
    &:not(.info-title) {
      text-align: end;
    }
  }

</style>