<template>
  <div>
    <div class="header-space filter-line">
      <div class="d-flex align-items-center">
        <AuButton
          class="switches-indent"
          :type="projectType == 'common' ? 'primary' : 'secondary'"
          @click="projectType='common'"
        >
          {{ $t("auCapitals.projects.typeProject") }}
        </AuButton>
        <AuButton
          class="switches-indent"
          center
          :type="projectType == 'pool' ? 'primary' : 'secondary'"
          @click="projectType='pool'"
        >
          {{ $t("auCapitals.projects.typePool") }}
        </AuButton>
        <AuButton
          v-if="loginAsTester"
          center
          :type="projectType == 'stackingPool' ? 'primary' : 'secondary'"
          @click="projectType='stackingPool'"
        >
          {{ $t("auCapitals.pools.stakingPools") }}
        </AuButton>
      </div>
      <div class="search-block">
        <AuInput
          v-model="search"
          icon="searchc"
          class="switches-indent"
          border-radius="22"
          :placeholder="$t('auCapitals.projects.placeholderSearch')"
        />
        <AuButton
          type="primary"
          width="130px"
          @click="showFilters=!showFilters"
        >
          <div class="filter-btn">
            {{ $t("auCapitals.projects.filter") }}
            <img
              src="/img/capital/grid.svg"
              alt="gird"
            >
          </div>
        </AuButton>
      </div>
    </div>
    <div
      v-if="showFilters"
      class="d-xl-flex filter-line"
    >
      <div class="col-xl d-flex switches">
        <AuSwitchButton
          :active="showFavorites"
          width="auto"
          border-radius="22"
          :label="$t('transactions.allPlaceholder')"
          @click="changeShowFavorites()"
        >
          <span
            v-if="showFavorites"
            class="following"
          >
            <AuIcon
              icon="star-following"
              @click="changeShowFavorites()"
            />  {{ $t("auCapitals.projects.showFavorites") }}
          </span>

          <span
            v-else
            class="following"
          >
            <AuIcon
              icon="star"
              @click="changeShowFavorites()"
            />  {{ $t("auCapitals.projects.showAll") }}
          </span>
        </AuSwitchButton>

        <AuSelect
          :value="status"
          :options="typeOptions"
          :placeholder="$t('auCapitals.projects.placeholderFilter')"
          border-radius="22"
          @input="value => status = value"
        />

        <AuSelect
          class="selector"
          :value="selectedCategoryId"
          :placeholder="$t('auCapitals.projects.placeholderCategory')"
          :options="sectionCapitalOptions"
          border-radius="22"
          @input="(value) => (selectedCategoryId = value)"
        />
      </div>
    </div>

    <ProgressLoader
      v-if="loadingFilters"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <div
      v-if="!loadingFilters"
      class="projects"
    >
      <template v-if="showStakingPoolProjects">
        <Pool
          v-for="proj in projects"
          :key="proj.id"
          :project="proj"
        />
      </template>
      <template v-else>
        <CapitalCard
          v-for="proj in projects"
          :key="proj.id"
          :project="proj"
        />
      </template>
    </div>

    <div
      class="load-more"
    >
      <AuButton
        v-if="currentTake < totalItems && !loadingFilters"
        type="primary"
        center
        width="125px"
        :is-progress="loading"
        @click="getMore"
      >
        {{ $t("auCapitals.projects.loadMore") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import CapitalCard from "@/components/capital/CapitalCard";
import Pool from "@/components/capital/Pool";
import ProgressLoader from "@/loaders/progress-bar";
import { convertCapital } from "@/components/capital/capitalHelper";

export default {
  name: "Projects",
  components: {
    Pool,
    CapitalCard,
    ProgressLoader
  },
  data() {
    return {
      search: null,
      projects: [],
      filtersFlag: {},
      selectedCategoryId: null,
      auCapitals: [],
      page: 1,
      totalItems: 1,
      loading: true,
      loadingFilters: false,
      status: "All",
      showFavorites: false,
      currentTake: 32,
      take: 32,
      projectType: "common", //common, pool, stackingPool
      showFilters: false,
      showStakingPoolProjects: false
    };
  },

  computed: {
    ...mapGetters({
      sectionCapital: "sectionCapital",
      searchedAuCapitals: "capitals/searchedAuCapitals",
      companies: "companies",
      followings: "capitals/followings",
      profile: "profile/profile"
    }),

    title() {
      const ret = process.env.VUE_APP_TITLE ?? " ";
      return ret[0].toUpperCase() + ret.toLowerCase().slice(1);
    },

    typeOptions() {
      return [
        { display: this.$t("auCapitals.projects.typeAll"), value: "All" },
        { display: "Open", value: "Open" },
        { display: "Waiting", value: "Waiting" },
        { display: "Closed", value: "Closed" },
        { display: "Completed", value: "Completed" },
        { display: "Declined", value: "Declined" }
      ];
    },

    sectionCapitalOptions() {
      const arrList = _.map(this.sectionCapital, (capital) => ({
        display: capital.name,
        value: capital.id
      }));

      arrList.unshift({
        display: "all",
        value: -1
      });

      return arrList;
    },

    loginAsTester() {
      return _.some(this.profile.roles, role => role == "ROLE_TEST");
    }
  },

  watch: {
    "$route.query": {
      immediate: true,
      async handler(newStatus) {
        this.loadingFilters = true;
        this.status = newStatus?.status || "All";
        this.showFavorites = newStatus?.isFollowed == "true" ? true : false;
        this.selectedCategoryId = newStatus?.sectionId === -1 ? null : newStatus?.sectionId;
        this.projectType = newStatus?.applicationType;
        await this.getCapital();
        this.loadingFilters = false;
      }
    },

    search: _.debounce(async function () {
      this.loadingFilters = true;
      await this.getCapital();
      this.loadingFilters = false;
    }, 500),

    async status() {
      this.loadingFilters = true;
      await this.getCapital();
      this.loadingFilters = false;
    },

    async selectedCategoryId() {
      this.loadingFilters = true;
      await this.getCapital();
      this.loadingFilters = false;
    },

    async projectType() {
      this.loadingFilters = true;
      await this.getCapital();
      this.loadingFilters = false;
    }
  },

  async mounted() {
    await this.getSectionCapital();
    await this.getCapital();
    await this.getFollowings();
    this.loading = false;
    for (let i = 0; i < this.sectionCapital; ++i) {
      let item = {};
      item[this.sectionCapital[i].id] = false;
      this.filtersFlag.push(item);
    }
  },

  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination",
      getFollowings: "capitals/getFollowings",
      sendCapital: "sendCapital"
    }),

    updateFilters() {
      this.$router.replace({
        query: { ...this.$route.query,
          status: this.status || "All",
          isFollowed: this.showFavorites,
          sectionId: this.selectedCategoryId === -1 ? null : this.selectedCategoryId,
          applicationType: this.projectType
        }
      });
    },

    async getMore() {
      this.loading = true;
      this.currentTake += this.take;
      await this.getCapital();
      this.loading = false;

      const loadMore = document.querySelector(".main-panel");
      const projects = document.querySelector(".projects");

      loadMore.scrollTop = projects.clientHeight;
    },

    async changeShowFavorites() {
      this.loading = true;
      this.showFavorites = !this.showFavorites;
      await this.getCapital();
      this.loading = false;
    },

    async pageChanged(actualPage) {
      this.page = actualPage;
      await this.getCapital();
    },

    async getCapital() {
      try {
        let statusFilter = this.status.toLowerCase();

        if (this.status == "All") {
          statusFilter = null;
        }
        else if (this.status == "Declined") {
          statusFilter = "not_success";
        }
        else if (this.status == "Completed") {
          statusFilter = "success";
        }
        this.updateFilters();

        let resp = await this.getAllAuCapitalsPagination({
          page: 1,
          itemsPerPage: this.currentTake,
          order: "desc",
          isFollowed: this.showFavorites ? this.showFavorites : null,
          description: this.search,
          status: statusFilter,
          sectionId: this.selectedCategoryId === -1 ? null : this.selectedCategoryId,
          applicationType: this.projectType
        });
        this.auCapitals = resp.data;
        this.totalItems = resp.totalItems;

        const capitalData = this.auCapitals;
        this.projects = await this.convertAuCapitals(capitalData);
      }
      catch (e) {
        console.log("error", e);
      }
    },

    selectedSearched() {
      const set = new Set();
      for (const { id } of this.searchedAuCapitals) {
        set.add(id);
      }

      const intersection = this.projects.filter(({ id }) => set.has(id));
      return intersection;
    },

    async convertAuCapitals(auCapitals) {
      let projects = [];
      for (const item of auCapitals) {
        const project = convertCapital(item, this.sectionCapital);
        projects.push(project);
      }

      return projects;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 400;
  font-size: 22px;
  margin-right: 40px;

  @media screen and (max-width: 1000px){
    display: none;
  }
}
.header-space {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px){
    flex-direction: column;
    gap: 20px;
  }

  .search-block {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
      padding-right: 15px;
    }
  }
}
.switches-indent {
  margin-right: 10px;
}
.switches {
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.filter-line {
  margin-top: 25px;
  margin-bottom: 15px;

  @media screen and (max-width:1199px) {
    margin-bottom: 25px;
  }
}
.filter-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.projects {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.selector {
  min-width: 220px;
}

.following {
  display: flex;
  gap: 5px;
}

.load-more{
  display: flex;
  justify-content: center;

  >button {
    padding: 20px;
    margin-top: 40px;
  }
}
</style>
