<template>
  <div>
    <div class="labels">
      <slot name="top-left" />
      <slot name="top-right" />
    </div>
    <ProgressBar
      :procent="procent"
      :status="status"
      :green="green"
    />
    <div class="labels">
      <slot name="bottom-left" />
      <slot name="bottom-right" />
    </div>
  </div>
</template>


<script>
import ProgressBar from "./ProgressBar.vue";

export default {
  components: {
    ProgressBar
  },

  props: {
    procent: {
      type: [Number, String],
      default: 0
    },
    status: {
      type: String,
      default: ""
    },
    green: {
      type: Boolean,
      default: false
    }
  }
};
</script>


<style lang="scss" scoped>
.labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>