<template>
  <div>
    <AuBreadcrumb :pages="breadcrumb" />
    <div class="card convert-main">
      <span class="title">
        {{ $t("convertToken.title") }}
      </span>
      <div class="convert-box">
        <span class="label-text">
          {{ $t("convertToken.from") }}
        </span>
        <div>
          <AuSelect
            :value="tokenFromId"
            :options="tokensFromOptions"
            @input="(v) => (tokenFromId = v)"
          />
          <span
            v-if="available"
            class="fs12"
          >
            {{
              $t("convertToken.available") +
                ` ${available} ${tokenFromMnemonic}`
            }}
          </span>
        </div>
        <span class="label-text">
          {{ $t("convertToken.to") }}
        </span>
        <AuSelect
          :value="tokenToId"
          :options="tokensToOptions"
          @input="(v) => (tokenToId = v)"
        />
        <div
          v-if="showAmount"
          class="convert-box"
        >
          <span class="label-text">
            {{ $t("convertToken.amount") }}
          </span>
          <AuInput
            v-model="amount"
            @input="checkInput"
          />
          <span>
            {{ $t("convertToken.rate") + ": " }}
            <span class="course-text">
              {{ `1 ${tokenFromMnemonic} ⟷ ${course} ${tokenToMnemonic}` }}
            </span>
          </span>
          <span class="exception">
            {{ $t("convertToken.commission") + ": " }}
            <span class="course-text">
              {{ commission }} %
            </span>
          </span>
          <span>
            {{ $t("convertToken.willReceive") + ": " }}
            <span class="course-text">
              {{ receive + " " + tokenToMnemonic }}
            </span>
          </span>
        </div>
        <div
          v-if="error?.length > 0"
          class="error-msg exchange-place"
        >
          {{ error }}
        </div>
        <div
          v-if="showAmount"
          class="exchange-place"
        >
          <AuButton
            type="primary"
            width="200px"
            center
            :is-progress="disable"
            @click="getExchange"
          >
            {{ $t("convertToken.exchange") }}
          </AuButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ConvertToken",
  data() {
    return {
      breadcrumb: [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" },
        { key: 1, name: this.$t("router.pages.briefcase"), path: "/briefcase" }],
      tokenFromId: null,
      tokenToId: null,
      amount: "",
      rate: 0,
      commission: "0",
      currencySup: new CurrencySupport(),
      error: "",
      available: null,
      disable: false
    };
  },
  computed: {
    ...mapGetters({
      defaultTokenId: "defaultToken/defaultTokenId",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultAutMnemonic: "defaultToken/defaultAutMnemonic",
      tokensUtility: "tokensUtility",
      ethAddresses: "ethAddresses"
    }),

    tokensFromOptions() {
      return [{ display: this.defaultTokenMnemonic, value: this.defaultTokenId }];
    },

    tokensToOptions() {
      return [{
        display: this.defaultAutMnemonic,
        value: _.find(this.tokensUtility, token => token.mnemonicEng === this.defaultAutMnemonic)?.id
      }];
    },

    tokenFromMnemonic() {
      return _.find(this.tokensFromOptions, token => token.value === this.tokenFromId)?.display;
    },

    tokenToMnemonic() {
      return _.find(this.tokensToOptions, token => token.value === this.tokenToId)?.display;
    },

    showAmount() {
      return this.tokenFromId && this.tokenToId;
    },

    course() {
      return BigNumber(this.rate).toFixed(4);
    },

    receive() {
      const buf = BigNumber(this.amount).minus(BigNumber(this.amount).multipliedBy(this.commission).dividedBy(100));
      return buf.isNaN() ? "-" : buf.multipliedBy(this.rate).toFixed(2);
    }
  },

  watch: {
    async tokenFromId(tokenIdNew) {
      if (this.tokenToId) {
        this.getRate();
      }
      await this.getAvailableSum(tokenIdNew);
    },

    tokenToId() {
      if (this.tokenFromId) {
        this.getRate();
      }
    }
  },

  async mounted() {
    const resp = await this.getSystemSettings();
    this.commission = resp.data?.systemConversationCommission ?? "0";
  },

  methods: {
    ...mapActions({
      conversion: "conversion",
      getAuOwnerByTokenId: "getAuOwnerByTokenId",
      getSystemSettings: "getSystemSettings"
    }),

    getRate() {
      this.rate = this.currencySup.getCurrency(this.tokenFromId, this.tokenToMnemonic);
    },

    checkInput() {
      this.amount = this.amount.match(/[0-9]*/);
    },

    async getAvailableSum(tokenId) {
      try {
        const resp = await this.getAuOwnerByTokenId({
          tokenId: tokenId,
          ownerAddress: this.ethAddresses[0].value
        });
        const available = resp[0].amount;
        const decimals = resp[0].auToken.decimals;
        let buf = BigNumber(available).dividedBy(BigNumber(10).pow(decimals));
        this.available = buf.isNaN() ? 0 : buf.toString(10);
      }
      catch (e) {
        this.available = 0;
      }
    },

    async getExchange() {
      this.disable = true;
      this.error = "";
      const params = {
        amount: this.amount.toString(),
        sendToken: `/api/au_tokens/${this.tokenFromId}`,
        recvToken: `/api/au_tokens/${this.tokenToId}`
      };
      try {
        const resp = await this.conversion(params);
        if (resp.status !== 200) {
          this.error = resp?.data?.message;
        }
        else {
          this.$toast.success(this.$t("convertToken.success"));
          this.disable = false;
          this.$router.push({ name: "briefcase" });
        }
      }
      catch (e) {
        this.error = e?.response?.data?.message;
        console.error(e);
      }
      this.disable = false;
    }
  }
};
</script>

<style scoped lang="scss">
.convert-main {
  padding: 20px;

  .title {
    font-size: 24px;
    border-bottom: 1px solid var(--color-70);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .label-text {
    color: var(--color-50);
  }

  .course-text {
    color: #279063;
  }

  .convert-box {
    display: flex;
    flex-direction: column;
    :nth-child(even) {
      padding-bottom: 25px;
    }

    .exception {
      padding-bottom: 0;
    }
  }

  .error-msg {
    color: red;
    font-size: 14px;
  }

  .exchange-place {
    display: flex;
    justify-content: center;
  }
}
</style>